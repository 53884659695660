import "./App.css";
import { useMemo } from "react";

import Home from "./Home";

import gif from "./alien.gif";
import Image from "./Logo.png";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

import {
  CrossMintButton,
  CrossMintProvider,
} from "@crossmint/client-sdk-react-ui";

require("@crossmint/client-sdk-react-ui/styles.css");

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <div id="branding">
              <div>
                <a href="https://www.lottoburritos.xyz/">
                  <img className="logo__image" src={Image} alt=""></img>
                </a>
              </div>
              <div>
                <img className="gif" src={gif} alt=""></img>
              </div>
            </div>
            <div
              id=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CrossMintProvider clientId="213ecc9a-81d4-4dbd-b7a2-a2f393b896db">
                <CrossMintButton
                  collectionTitle="Lotto Burritos"
                  collectionDescription="3,333 hand drawn generative art on the Solana Network acting as entries into THE lottery of Solana."
                  collectionPhoto="https://static.wixstatic.com/media/9842b5_6f0c784b385b4a18a58fb314d61e6b21~mv2.png/v1/fill/w_114,h_203,al_c,q_85,usm_0.66_1.00_0.01/SombreroBurrito.webp"
                />
              </CrossMintProvider>
              <Home
                candyMachineId={candyMachineId}
                connection={connection}
                txTimeout={txTimeout}
                rpcHost={rpcHost}
              />
            </div>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
